import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";

const DialogTags1 = ({ open, handleClose, startupData, innovationId }) => {
  const [categories, setCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/taxonomies?type=category`
        );
        const data = response.data.data;

        // Flatten the hierarchy into a single level under each parent
        const flattenedCategories = data.map((category) => ({
          ...category,
          children: flattenHierarchy(category.children || []),
        }));

        setCategories(flattenedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    // Prefill selected tags from startupData
    if (startupData?.taxonomies?.categories) {
      const initialTags = startupData.taxonomies.categories.map((category) => ({
        id: category.id,
        name: category.name,
      }));
      setSelectedTags(initialTags);
    }
  }, [startupData]);

  // Helper to flatten the hierarchy
  const flattenHierarchy = (nodes) => {
    let flatList = [];
    nodes.forEach((node) => {
      flatList.push(node); // Add the node itself
      if (node.children && node.children.length > 0) {
        flatList = flatList.concat(flattenHierarchy(node.children)); // Recursively add children
      }
    });
    return flatList;
  };

  // Handle tag selection
  const handleTagSelect = (tag) => {
    if (!selectedTags.some((t) => t.id === tag.id)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Handle tag removal
  const handleTagRemove = (tagId) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== tagId));
  };

  // Handle Save
  const handleSave = async () => {
    try {
      // Prepare data for the PATCH request
      const updatedTags = selectedTags.map((tag) => tag.id);
      const payload = { categories: updatedTags };

      // Send PATCH request
      await axios.patch(`${process.env.REACT_APP_API_URL}/innovations/${innovationId}`, payload);

      console.log("Tags updated successfully:", updatedTags);
      handleClose(); // Close dialog after saving
    } catch (error) {
      console.error("Error saving tags:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#191919",
          color: "white",
          fontFamily: "Urbanist",
        }}
      >
        Sector of Competence
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white" }}>
        {/* Selected Tags */}
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedTags.map((tag) => (
            <Chip
              key={tag.id}
              label={tag.name}
              onDelete={() => handleTagRemove(tag.id)}
              deleteIcon={
                <span
                  style={{
                    color: "rgb(var(--global-color-primary))",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </span>
              }
              sx={{
                backgroundColor: "transparent",
                border: "1.5px solid rgb(var(--global-color-primary))",
                borderRadius: "5px",
                fontFamily: "Urbanist",
                color: "rgb(var(--global-color-primary))",
                fontWeight: "bold",
              }}
            />
          ))}
        </div>

        {/* Accordion for Categories */}
        {categories.map((category) => (
          <Accordion key={category.id} sx={{ backgroundColor: "#333", color: "white" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}>
              <div className="flex items-center gap-2">
                {category.logo && (
                  <img src={category.logo} alt={category.name} style={{ width: "24px" }} />
                )}
                <span>{category.name}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-wrap gap-2">
                {category.children.map((child) => (
                  <Chip
                    key={child.id}
                    label={child.name}
                    onClick={() => handleTagSelect({ id: child.id, name: child.name })}
                    sx={{
                      backgroundColor: "white",
                      fontFamily: "Urbanist",
                      color: "black",
                      fontWeight: "bold",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "black",
                      },
                    }}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919" }}>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "gray",
            color: "white",
            "&:hover": { backgroundColor: "rgba(128, 128, 128, 0.8)" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTags1;
