import React, { useEffect, useState, useRef } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import Badge from "@mui/material/Badge";
import { socket } from "../index";

export default function ChatsAdmin() {
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedChatMessages, setSelectedMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);

  // Listener per i messaggi
  useEffect(() => {
    if (!socket) {
      console.error("Socket is not initialized.");
      return;
    }

    const handleNewMessage = (payload) => {
      if (payload.user_id === selectedChatId && payload.sent_from_user === true) {
        // Aggiorna i messaggi della chat aperta
        setSelectedMessages((prev) => [...prev, payload]);
      }
    };

    const handleChatHistory = (payload) => {
      if (payload.messages) {
        setSelectedMessages((prev) => [
          ...payload.messages.reverse(), // Aggiungi i nuovi messaggi sopra
          ...prev,
        ]);
      }
    };

    const handleChatList = (payload) => {
      if (payload.chats) {
        setChats((prevChats) => {
          const existingUserIds = new Set(prevChats.map((chat) => chat.id));
          const newChats = payload.chats.filter(
            (chat) => !existingUserIds.has(chat.user_id)
          ).map((chat) => ({
            id: chat.user_id,
            name: chat.name,
            date: chat.date,
          }));
          return [...prevChats, ...newChats];
        });
      }
    };

    socket.on("innovations chat list", handleChatList);
    socket.emit("innovations chat list");

    socket.on("innovations chat message", handleNewMessage);
    socket.on("innovations chat history", handleChatHistory);

    return () => {
      socket.off("innovations chat message", handleNewMessage);
      socket.off("innovations chat history", handleChatHistory);
      socket.off("innovations chat list", handleChatList);
    };
  }, [selectedChatId]);

  // Effetto per lo scroll automatico quando arrivano nuovi messaggi
  useEffect(() => {
    if (!isLoadingPrevious) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedChatMessages, isLoadingPrevious]);

  // Cambia la chat selezionata
  const handleChatSelection = (chatId) => {
    setSelectedChatId(chatId);
    setSelectedMessages([]);
    socket.emit("innovations chat history", { messageId: null, toId: chatId });
  };

  // Carica i messaggi precedenti
  const loadPreviousMessages = () => {
    if (selectedChatMessages.length > 0) {
      setIsLoadingPrevious(true);
      const firstMessageId = selectedChatMessages[0]?.id || null;

      // Salva la posizione dello scroll
      const currentScrollHeight = chatContainerRef.current.scrollHeight;
      const currentScrollTop = chatContainerRef.current.scrollTop;

      socket.emit("innovations chat history", { messageId: firstMessageId, toId: selectedChatId });

      socket.once("innovations chat history", (payload) => {
        if (payload.messages) {
          setSelectedMessages((prev) => [
            ...payload.messages.reverse(),
            ...prev,
          ]);

          // Ripristina la posizione dello scroll
          setTimeout(() => {
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight - currentScrollHeight + currentScrollTop;
          }, 0);
        }
        setIsLoadingPrevious(false);
      });
    }
  };

  const handleAddMessage = () => {
    if (newMessage.trim() && selectedChatId !== null) {
      const messagePayload = {
        toId: selectedChatId,
        content: newMessage.trim(),
      };
      socket.emit("innovations chat message", messagePayload);
      setNewMessage("");
      setSelectedMessages((prev) => [
        ...prev,
        { ...messagePayload, sent_from_user: false },
      ]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleAddMessage();
    }
  };

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date(); // Usa `dateString` se definito, altrimenti ora corrente
    return isNaN(date) ? new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) 
                       : date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  
  const formatDay = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date(); // Usa `dateString` se definito, altrimenti ora corrente
    return isNaN(date)
      ? new Date().toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : date.toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
  };

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[calc(100vh-104px)] flex flex-col">
        <div className="flex flex-row h-full">
          {/* Colonna sinistra */}
          <div className="flex flex-col gap-2 w-60 min-w-60 h-full bg-[rgb(255,255,255,.2)] p-2 pt-6">
            <TextField
              variant="outlined"
              placeholder="Search startups"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "#818181" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                display: "none",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#818181", fontFamily: "Urbanist, sans-serif" },
                },
              }}
            />

            {chats.map((chat) => (
              <Badge badgeContent={0} color="primary" key={chat.id}>
                <Button
                  sx={{
                    display: "flex",
                    gap: "8px",
                    width: "100%",
                    alignItems: "center",
                    position: "relative",
                    backgroundColor:
                      chat.id === selectedChatId ? "rgba(197, 255, 85, 0.8)" : "transparent",
                  }}
                  onClick={() => handleChatSelection(chat.id)}
                >
                  <div
                    className="flex flex-col gap-0 w-full text-left text-white normal-case font"
                    style={{
                      color: chat.id === selectedChatId ? "#000" : "white",
                      fontWeight: chat.id === selectedChatId ? "700" : "400",
                    }}
                  >
                    <span>{chat.name}</span>
                    <span className="text-[10px]">ID: {chat.id}</span>
                  </div>
                </Button>
              </Badge>
            ))}
          </div>

          {/* Colonna destra */}
          <div className="flex flex-col flex-grow h-full bg-black">
            <div
              ref={chatContainerRef}
              className="flex flex-col flex-grow p-5 gap-5 h-0 overflow-y-auto"
            >
              {/* Pulsante per caricare i messaggi precedenti */}
              <div className="text-center p-2">
                {selectedChatMessages.length > 0 && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                    onClick={loadPreviousMessages}
                    disabled={isLoadingPrevious}
                  >
                    {isLoadingPrevious ? "Loading..." : "Load Previous Messages"}
                  </Button>
                )}
              </div>

              {/* Messaggi */}
              {selectedChatMessages.length > 0 ? (
                selectedChatMessages.map((m, index) => (
                  <div
                    key={index}
                    className={`flex flex-col gap-1 ${
                      m.sent_from_user ? "items-end" : "items-start"
                    }`}
                  >
                    <span className="font-semibold text-white">
                      {m.sent_from_user ? "User" : "You"}
                    </span>
                    <div className={`p-2 bg-gray-800 text-white w-fit rounded-2xl min-w-xs max-w-[80%] flex flex-col gap-1 
                      border-radius: 50px;
                      padding-left: 15px;
                      padding-right: 15px;
                    ${
                      m.sent_from_user ? "items-end" : "items-start"
                    }
                    `}>
                      {m.content}
                    </div>
                    <span style={{fontSize: "10px", color:"#ddd"}}>{formatDate(m.sent_at)} &bull; {formatDay(m.sent_at)}</span>
                  </div>
                ))
              ) : (
                <div className="text-white text-center">No messages available for this chat.</div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Campo di input */}
            <div className="p-5 bg-black flex gap-5">
              <TextField
                variant="outlined"
                fullWidth
                multiline
                value={newMessage}
                placeholder="Write a message..."
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Urbanist",
                    "& fieldset": { borderColor: "#818181", fontFamily: "Urbanist, sans-serif" },
                  },
                }}
                onChange={handleNewMessage}
                onKeyDown={handleKeyDown}
                disabled={selectedChatId === null}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgb(var(--global-color-primary))",
                  color: "black",
                }}
                onClick={handleAddMessage}
                disabled={selectedChatId === null}
              >
                <SendIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
