import React, { createContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { io } from "socket.io-client";

// Configura l'intercettore per aggiungere il token
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Determina il valore di `authtoken`
const innovation = localStorage.getItem("innovation");
let authtoken = "";
let startup = "";

if (innovation === "true") {
  authtoken = localStorage.getItem("token");
  startup = "startup";
} else {
  authtoken = localStorage.getItem("token");
}

// Connessione al socket
export const socket = io(process.env.REACT_APP_BASE_URL, {
  auth: { token: localStorage.getItem("token") || "" }, // Usa il token se disponibile
  autoConnect: true, // Connessione automatica
});

// Log per connessione socket
socket.on("connect", () => {
  console.log("Socket connected:", socket.id);
});

socket.on("disconnect", () => {
  console.log("Socket disconnected");
});

// Funzione per aggiornare le credenziali
export const updateSocketAuth = (newToken) => {
  // Disconnetti il socket se è connesso
  if (socket.connected) {
    socket.disconnect();
  }

  // Aggiorna il token di autenticazione
  socket.auth = { token: newToken };

  // Riconnetti il socket con il nuovo token
  socket.connect();
};

export const NotificationContext = createContext();

const Root = () => {
  const [notifications, setNotifications] = useState([]);
  const [badgeVisible, setBadgeVisible] = useState(false); // Stato per il badge

  // Listener per notifiche
  useEffect(() => {
    const handleNotification = (payload) => {

      //console.log(payload);
      
      setNotifications((prev) => {
        const isDuplicate = prev.some(
          (n) => n.content === payload.content && n.date === payload.date
        );
        if (!isDuplicate) {
          setBadgeVisible(true); // Mostra il badge per nuove notifiche
        }
        return isDuplicate ? prev : [...prev, payload];
      });
    };

    socket.on("notifications", handleNotification);

    return () => {
      socket.off("notifications", handleNotification);
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        badgeVisible,
        setBadgeVisible,
        socket,
      }}
    >
      <App startup={startup} />
    </NotificationContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();
